import { Field, ErrorMessage } from 'formik';
import * as _ from "lodash"
import { Label } from '../Label';
import React, { useEffect, useState, useRef } from "react";
import { DayPicker } from "react-day-picker";

export const FormikDate = ({
  name,
  formikProps,
  required = false,
  label = null,
  placeholder = null,
  readOnly = false
}) => {
  const hasError = formikProps?.errors && _.get(formikProps?.touched || {}, name) && _.get(formikProps.errors, name);
  const errorClasses = 'ring-red-500 border-red-500 focus:ring-red-500 focus:border-red-500'
  const currentYear = new Date().getFullYear();
  const fromYear = currentYear - 110;
  const toYear = currentYear;
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(false);

  useEffect(() => {
    const date = _.get(formikProps?.values || {}, name);
    if (!isNaN(date)) {
      setSelectedDate(date);
    }
  }, [setSelectedDate, name, formikProps]);

  const onSelectDate = (date, name, formikProps) => {
    formikProps.setFieldValue(name, new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())));
    setShowCalendar(false);
  }
  
  const handleOnClick = () => {
    setShowCalendar(true);
  }
  
  const dateFormater = (date) => {
    if (date === "") return "";
  
    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();
  
    return `${month} ${day}, ${year}`;
  }

  const innerRef = useOuterClick(ev => {
    if (showCalendar) setShowCalendar(false);
  })

  function useOuterClick(callback) {
    const callbackRef = useRef(); // initialize mutable ref, which stores callback
    const innerRef = useRef(); // returned to client, who marks "border" element
  
    // update cb on each render, so second useEffect has access to current value 
    useEffect(() => { callbackRef.current = callback; });
    
    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
      function handleClick(e) {
        if (innerRef.current && callbackRef.current && 
          !innerRef.current.contains(e.target)
        ) callbackRef.current(e);
      }
    }, []); // no dependencies -> stable click listener
        
    return innerRef; // convenience for client (doesn't need to init ref himself) 
  }

  return (
    <div>
      {
        label && <Label name={name} label={label} required={required} />
      }
      <div className="mt-1">
        <Field
          type="text"
          name={name}
          className={`shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400 ${hasError ? errorClasses : ''} ${readOnly ? 'bg-gray-200 cursor-not-allowed' : ''}`}
          placeholder={placeholder}
          readOnly={readOnly}
          autocomplete="off"
          value={selectedDate ? dateFormater(selectedDate) : ""}
          onChange={(e) => e.preventDefault()}
          onClick={handleOnClick}
        />
        <ErrorMessage name={name} component="div" className="text-red-500" />
      </div>
      {showCalendar &&
        <div className="absolute bg-brand-background rounded-sm shadow-sm z-10" ref={innerRef}>
          <DayPicker
            mode="single"
            fromYear={fromYear}
            toYear={toYear}
            defaultMonth={new Date()}
            onSelect={(e) => onSelectDate(e, name, formikProps)}
            captionLayout="dropdown"
          />
        </div>
      }

    </div>
  );
}